var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"Produtos de Segmento"}},[_c('p',{staticClass:"header-filter-title"},[_vm._v("Filtros")]),_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-2"},[_c('header-filter',{attrs:{"filters-to-show":['industry']},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('mf-button',{attrs:{"color":"primary","label":"Cadastro","icon":"add"},on:{"click":_vm.create}})],1),_c('mf-card-container',{attrs:{"placeholder":"Busca","use-search":"","title":"Produtos","description":"Lista de produtos de segmento"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-data-table',{attrs:{"loading":_vm.$apollo.queries.products.loading,"headers":_vm.headers,"items":_vm.products,"search":_vm.debounceSearch,"options":_vm.options,"server-items-length":_vm.dataCount,"footer-props":{ itemsPerPageOptions: [10, 15, 20] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at,'dd/MM/yyyy HH:mm'))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link",on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""}},on),[_c('mf-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.erase(item)}}},[_c('v-list-item-title',[_vm._v("Apagar")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }